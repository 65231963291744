import React, { useRef } from 'react';
import { useReactToPrint } from 'react-to-print';
import PrintWz from './print-doc'; // Імпортуйте ваш компонент PrintWz
import PrintCmr from './cmr-doc';  // Імпортуйте ваш компонент PrintCmr
import PrintWG from './wyrob'; 
import PrintEN from './wyrobEN'; 
import PrintGera from './gera-krt'; 
import PrintFR from './wyrobFr'; 
import PrintGeranova from './printGera'; 
import Plan from './plan'; 

const PrintButton = ({host, data,plan, typeDoc }) => {
    const componentRef = useRef();

    const handlePrint = useReactToPrint({
        content: () => componentRef.current
    });
    
    const renderComponent = () => {
        if(typeDoc === 'wz' ){
                return (
                        <PrintWz ref={componentRef} data={data} />
                    )
        } else if (typeDoc === 'cmr' ){
                return (
                        <PrintCmr ref={componentRef} data={data} />
                    )
        } else if (typeDoc === 'wg' ){
            return (
                    <PrintWG ref={componentRef} data={data} />
                )
        }else if (typeDoc === 'wg-en' ){
            return (
                    <PrintEN ref={componentRef} data={data} />
                )
        }else if (typeDoc === 'number' ){
            return (
                    <PrintGera ref={componentRef} data={data} />
                )
        }else if (typeDoc === 'wg-fr' ){
            return (
                    <PrintFR ref={componentRef} data={data} />
                )
        }else if (typeDoc === 'gera' ){
            return (
                    <PrintGeranova ref={componentRef} data={data} />
                )
        }else if (typeDoc === 'plan' ){
            return (
                    <Plan ref={componentRef}  host={host} data = {plan} />
                )
        }
};
    return (
        <div>
            <button onClick={handlePrint}>Print</button>
            {renderComponent()}
        </div>
    );
}

export default PrintButton;
