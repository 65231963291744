
import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import './header.css';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { BiX, BiCheck, BiSolidSend, BiSolidCloudDownload,BiSolidRadio ,BiShare,BiSolidChat,BiArrowFromTop,BiArrowFromBottom  } from "react-icons/bi";

class AppHeader extends Component {
    constructor(props) {
        super(props);
        this.endOfMessagesRef = React.createRef();
        this.state = {
            data: [],
            selectedOption: 'Dostawa',
            handleBtnChange:'',
            handleBtnChangePal:'',
            buttons: {
                button1: true, // Ваша активна кнопка
                button2: false,
              },
            checkBnt:'active', 
            btnDate:true,
            musik:false,
            playList:[
                {name:'ESKA Poznan', url:'https://pldm.ml/radio?url=https://www.eskago.pl/radio/eska-poznan'},
                {name:'RFM maxxx', url:'https://rs103-krk.rmfstream.pl/rmf_maxxx'},
                {name:'ESKA do pracy',url:'https://pldm.ml/radio?url=https://www.eskago.pl/radio/eska-do-pracy'},
                {name:'ESKA HOT100',url:'https://ic2.smcdn.pl/2140-2.aac'},
                {name:'ESKA rap',url:'https://ic1.smcdn.pl/6190-1.mp3'},
                {name:'LUX FM',url:'https://icecast.luxnet.ua/lux'},
                {name:'HitFM',url:'https://online.hitfm.ua/HitFM'},
                {name:'KissFM',url:'https://online.kissfm.ua/KissFM'},
                {name:'NewOnce',url:'https://stream.open.fm/374'},
                {name:'Rammstein',url:'https://radio21.streamabc.net/radio21-rammstein-mp3-192-3002006?sABC=670p7p7s%230%23s0ooq63967667139842q67962np0933s%23&aw_0_1st.playerid=&amsparams=playerid:;skey:1728871551'}
            ],
            actilveFrame:'https://pldm.ml/radio?url=https://www.eskago.pl/radio/eska-poznan',
            activeButtonIndex: 2,
            activeStation:'ESKA Poznan',
            gpt: false,
            response: '',
            loading: false,
            error: null,
            messages:[],
            header:false
        };
    }
    
    //GPT\
scrollToBottom = () => {
    if (this.state.gpt && this.endOfMessagesRef.current) {
        const container = this.endOfMessagesRef.current; // Отримуємо контейнер
        container.scrollTop = container.scrollHeight; // Прокручуємо вниз
    }
}

componentDidUpdate(prevProps, prevState) {
    // Прокручувати, якщо нове повідомлення додано
    if (prevState.messages.length !== this.state.messages.length) {
        this.scrollToBottom();
    }
}
    
 handleBtnChange = (e) => {
    this.setState({ handleBtnChange: e.target.value });
  };

 handleSubmit = async (e) => {
     const apiKey = process.env.REACT_APP_OPENAI_API_KEY;
    const my_messages = this.state.messages;
    e.preventDefault();
    this.setState({ loading: true, error: null });
    if (!this.state.handleBtnChange) return;
    
    this.setState({messages:[...my_messages, { role: "user", content: this.state.handleBtnChange }]})
    try {

      const result = await axios.post('https://api.openai.com/v1/chat/completions', {
        model: "gpt-3.5-turbo",
        messages: [...my_messages, { role: "user", content: this.state.handleBtnChange }]
      }, {
        headers: {
          'Authorization': `Bearer ${apiKey}`,
          'Content-Type': 'application/json',
        }
      });
           this.setState({
                messages: [...my_messages, { role: "user", content: this.state.handleBtnChange }, { role: 'assistant', content: result.data.choices[0].message.content }],
                handleBtnChange: ''
            }, this.scrollToBottom);

    } catch (err) {
      console.error('Error fetching data from OpenAI API:', err);
      this.setState({ error: 'starcie na dzisiaj' ,loading: false ,handleBtnChange: ''});

    } finally {
      this.setState({ loading: false });

      
    }
  }; 
    

// message
// Функція для виконання POST-запиту
 postData = async (data) => {
    try {
        const url =`${this.props.host}/post/message` 
        const postData = {
            // Додайте ваші дані, наприклад:
            date: data.date,
            message: data.message,
            status: data.status
        };
        const response = await axios.post(url, postData);
        this.props.messageCallback(response.data)
        return response.data;
    } catch (error) {
        return null;
    }
};

editDataMessage = (id , newData) => {
    axios.put(`${this.props.host}/put/message/${id}`, newData)
      .then(response => {
      })
      .catch(error => {
         window.alert('Wystapil bląd, brak id')

      });
  };

deleteMessageFromServer = (id) => {
    axios.delete(`${this.props.host}/delete/message/${id}`)
      .then(response => {
       
       this.setState(prevState => ({
          data: prevState.data.filter(item => item._id !== id),
        }));
      })
      .catch(error => {
        window.alert('Wystapil bląd');

      });
  };
  

  btnAddMasage = () => {
    let year = new Date().getFullYear(),
    mouth = new Date().getMonth() + 1,
    day = new Date().getDate();
    let formatDay = day < 10 ?  "0"+day : day,
    formatMouth = mouth < 10 ?  "0"+mouth : mouth

    this.postData( { date: `${formatDay}.${formatMouth}.${year}`, message: this.state.handleBtnChange, status:'active' })

    this.setState({ handleBtnChange: '' });
}

handleButtonClick = (buttonId) => {
    // Створюємо копію об'єкта стану
    const updatedButtons = { ...this.state.buttons };
  
    // Встановлюємо активну кнопку і вимикаємо інші
    for (const button in updatedButtons) {
      updatedButtons[button] = button === buttonId;
    }
  
    // Оновлюємо стан
    this.setState({ buttons: updatedButtons });
    
  }




handleBtnActive = (index) => {
    let year = new Date().getFullYear(),
    mouth = new Date().getMonth() + 1,
    day = new Date().getDate();
    let formatDay = day < 10 ?  "0"+day : day,
    formatMouth = mouth < 10 ?  "0"+mouth : mouth
    const updatedData = [...this.props.listData];
    const id = updatedData[index]._id;
    const newData = {status : 'akcept',date :`${formatDay}.${formatMouth}.${year}`}
    updatedData[index].status = 'akcept';
    updatedData[index].date = `${formatDay}.${formatMouth}.${year}`;
    updatedData.splice(index, 1);
    this.editDataMessage(id, newData )
    this.props.messageCallbackList(updatedData)

}

handleBtnDelite = (index) => {
    const updatedData = [...this.props.listData];
    this.deleteMessageFromServer(updatedData[index]._id)
    updatedData.splice(index, 1);
    this.props.messageCallbackList(updatedData)
    
}
btnSend = () => {
   if(this.state.handleBtnChange !== '' && this.state.gpt === false) {
       return (<button className='btn-send' onClick={this.btnAddMasage}><BiSolidSend /></button> )
       }else if(this.state.gpt && !this.state.loading && this.state.handleBtnChange !== ''){
           return (<button className='btn-send' onClick={this.handleSubmit}><BiSolidSend /></button> )
       } else if(this.state.gpt && this.state.loading ){
           return (<button className='btn-send' ><Spinner animation="border"  variant="warning"/></button> )
       }else {return ( <button className='btn-send'title='Odśwież'onClick={() => this.props.fetchDataMessage()}><BiSolidCloudDownload /></button>) }
}
handleButtonClickList = (index) => {
this.setState({ activeButtonIndex: index });
}
OnlineRadioPlayer = () => {
    return (
      <div>
        <h2>Online Radio : { this.state.activeStation}</h2>
        <ReactPlayer
          url={this.state.actilveFrame}
          playing={true}
          controls={true}
          width='100%'
          height='50px'
        />
      </div>
    );
  }
  
lastData = () => {
    
    return this.props.lastData.slice().reverse().map((item, index) => {
        const subcountDigits = item.subcount.replace(/\D/g, '');
        return (
           <div className="last-data" key={index}>
            {index + 1}. <strong>{item.name}</strong> - <span>{item.id}</span> - <span className="last-title">
                {item.title.length > 22 ? item.title.substring(0, 18) + '...' : item.title}
            </span> - {subcountDigits=== ""? 0 : subcountDigits} pal.
          </div>
        );
    });
};

gptPost = () => {
    
    if(this.state.messages.length === 0) {
        return <p className='text-area_chat'  style={{ fontSize: '26px'} } >Zapytaj mnie o cokolwiek ... </p>
    }
    return (
        <ul className='text-area_chat'  >
            {this.state.messages.map( (msg,index) => {
            return (
               <li  key={index}>
                    <span  className={`text-area_list-chat_${msg.role}`}> {msg.role}: <br/> {msg.content} </span>
                   
                </li>
                )
        }
        )}

        </ul>
    )}

render() {
    const { handleBtnChange, playList } = this.state;
    const data = this.props.listData;
    const playListItem = playList.map((item, index) => {
        return (
            <button key={item.name} className={index === this.state.activeButtonIndex ? "active-list musik_btn-list" : "musik_btn-list"}
                onClick={() => {
                    this.setState({ actilveFrame: item.url });
                    this.handleButtonClickList(index);
                    this.setState({ activeButtonIndex: index });
                    this.setState({ activeStation: item.name });
                }}>{item.name}</button>
        )
    });

    let message = data.map((title, index) => {
        let content = null;
        if (title.status !== 'akcept') {
            content = (
                <div className='text-area_list-btn'>
                    <button className='list-btn' onClick={() => this.handleBtnDelite(index)}>
                        <BiX />
                    </button>
                </div>
            );
        } else {
            content = this.state.btnDate ?
                <span onClick={() => this.setState({ btnDate: !this.state.btnDate })}>{title.date}</span> :
                <button className='list-btn_date' onClick={() => { this.handleBtnDelite(index);
                this.setState({ btnDate: !this.state.btnDate }) }}><BiX /></button>
        }

        return (
            <li className='text-area_list' key={title._id}>
                <span className='text-area_list-item'>{title.message}</span>
                <div className='text-area_list-date'>{content}</div>
            </li>
        );
    });

    return (
        <>
            <div className={this.state.header === true ? 'header_show' : 'header'} >
               <div className="musik_btn-box"> {this.state.musik === false ? <><button className='musik_btn' onClick={() => { this.setState({ musik: !this.state.musik }) }}><BiSolidRadio /></button> {this.props.listData.length > 0 ? <div className="musik_btn-alarm"><BiSolidChat /></div>:<></>} </> :
                   <> <button className='musik_btn' onClick={() => { this.setState({ musik: !this.state.musik }) }}> <BiShare /> </button> {this.props.listData.length > 0 ? <div className="musik_btn-alarm"><BiSolidChat /></div>:<></>} </>
                } </div>
                <div className="header_arrow-show" onClick={()=> {this.setState({header:!this.state.header})}}>{this.state.header ?  <BiArrowFromBottom />:<BiArrowFromTop /> }</div>
                <div className='text-area'  >
                    <div className='text-area_nav'>
                        <div className='text-area_inputbar'>
                            <input className='text-area_input'
                                type="text" placeholder="Napisz wiadomość"
                                value={handleBtnChange}
                                onChange={this.handleBtnChange} />
                            {this.btnSend()}
                        </div>
                        <div className='text-area_filter-bar'>
                            <button className={`text-area_filter-bar_btn ${this.state.buttons.button1 ? 'active' : ''}`}
                                onClick={() => {
                                    this.handleButtonClick('button1');
                                    this.props.activeList()
                                    this.setState({ gpt: false, messages:[]});
                                    
                                }}>Notatki</button>
                            <button className={`text-area_filter-bar_btn ${this.state.buttons.button2 ? 'active' : ''}`}
                                onClick={() => { this.handleButtonClick('button2'); this.setState({ gpt: true});}}>
                                Chat
                            </button>
                        </div>
                    </div>
                    <div className='messages-container' ref={this.endOfMessagesRef} >
                        
                          {this.state.gpt ? this.gptPost() : <ul className='text-area_ul'>{message }</ul>}
                    </div>

                </div>
                {this.state.musik === false ? <div className='header-pal'>
                    <h3>Ostatnio dodani zlecenia</h3>
                    {this.lastData()}
                </div> : <div className={`header-pal ${this.state.musik ? 'active-musik' : ''} `}>
                    <div className='radio fixed-radio'>{this.OnlineRadioPlayer()}</div>
                    <div className='scrollable-list'>{playListItem}</div>
                </div>}
            </div>
            
        </>
    );
}
}

export default AppHeader;