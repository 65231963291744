import React, { Component } from 'react';
import Table from 'react-bootstrap/Table';
import './wz.scss';
import pencil from"./img/pencil.png";
import recycle from"./img/recycle-bin.png";
import clipboard from"./img/clipboard.png";
import axios from 'axios';
import {BiSolidClinic} from "react-icons/bi";

class Plan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            index: -1,
            data: [...this.props.data],
            editedRow: {}
        };
    }
saveEnterBtn = (event) => {
    if(this.state.index !== -1 && event.key === 'Enter') {
        this.finishEdit()
    }
}

sendDataToServer = (data) => {
    axios.post(`${this.props.host}/plan/post`, data)
      .then(response => {

        this.setState(prevState => ({
            data: [...prevState.data, response.data]
        }));
      })
      .catch(error => {
      window.alert('Wystapil blad, sprawdź połączenie z internetem')
      });
  };

  editDataFromServer = (id , newData) => {
    axios.put(`${this.props.host}/edit/${id}`, newData)
      .then(response => {
  
      })
      .catch(error => {
         window.alert('Wystapil blad, brak id')
  
      });
  };

  deleteMessageFromServer = (id) => {
    axios.delete(`${this.props.host}/plan/cut/${id}`)
      .then(response => {
       
       this.setState(prevState => ({
          data: prevState.data.filter(item => item._id !== id),
        }));
      })
      .catch(error => {
        window.alert('Wystapil bląd');

      });
  };

  addRow = async () => {

    const newItem = {
      job: '',
      name: '',
      title: '',
      comment: '',
      send: ''
    };

    this.sendDataToServer(newItem)

  };

finishEdit =  () => {
const {index, editedRow, data} = this.state;
if (index !== -1) {
    this.setState(prevState => {
        const updatedData = [...prevState.data];
        data[index] = editedRow;
        this.editDataFromServer (editedRow._id, editedRow)
        return{editedData:updatedData}
    })
    this.setState({editedRow:{},index: -1})
        
}
};


 startEdit = async (row) => {
  const {index } = this.state;
  if(index !== -1) {
      await this.finishEdit()
  }
this.setState({ index: row , editedRow:this.state.data[row] });
};

handleFieldChange = (index, field, event) => {
    if (index !== -1) {
        const newData = [...this.state.data];
        newData[index] = {
            ...newData[index],
            [field]: event.target.value,
        };
        this.setState({
            data: newData,
            editedRow: newData[index],
        });
    }
};

toggleRowSelection = (index) => {
    const selectedRow = this.state.data[index];
    this.setState({
        index: index,
        editedRow: { ...selectedRow }
    });
};




    render() {
        let year = new Date().getFullYear(),
            mouth = new Date().getMonth() + 1,
            day = new Date().getDate() + 1;
        let formatDay = day < 10 ?  "0"+day : day,
            formatMouth = mouth < 10 ?  "0"+mouth : mouth

        const rows = this.state.data.map((item, index) => {
            return (
                <tr key={index} >
                    <td className="fw700 title-copy fz-14">
                        {this.state.index === index ? (
                            <input
                                type="text"
                                maxLength={30}
                                value={this.state.editedRow.name || ''}
                                onChange={(e) => this.handleFieldChange(index, 'name', e)}
                                 className="plan_input" 
                            />
                        ) : (
                            item.name
                        )}
                    </td>
                    <td className="fz-12" >
                        {this.state.index === index ? (
                            <input
                                type="text"
                                maxLength={58}
                                value={this.state.editedRow.title || ''}
                                onChange={(e) => this.handleFieldChange(index, 'title', e)}
                                className="plan_input"
                            />
                        ) : (
                            item.title
                        )}
                    </td>
                    <td className="fz-12" >
                        {this.state.index === index ? (
                            <input
                                type="text"
                                maxLength={58}
                                value={this.state.editedRow.job || ''}
                                onChange={(e) => this.handleFieldChange(index, 'job', e)}
                                className="plan_input"
                            />
                        ) : (
                            item.job
                        )}
                    </td>
                    <td className="fz-12" >
                        {this.state.index === index ? (
                            <input
                                maxLength={40}
                                value={this.state.editedRow.comment || ''}
                                onChange={(e) => this.handleFieldChange(index, 'comment', e)}
                                className="plan_input"
                            />
                        ) : (
                            item.comment
                        )}
                    </td>
                    <td className="fz-12" >
                        {this.state.index === index ? (
                            <input
                                maxLength={40}
                                value={this.state.editedRow.send || ''}
                                onChange={(e) => this.handleFieldChange(index, 'send', e)}
                                className="plan_input"
                            />
                        ) : (
                            item.send
                        )}
                    </td>
                    <td  >
                        {this.state.index === index ?  <button onClick={() => this.finishEdit()} className='plan_btn hide_print'><img src={clipboard} alt="redaguj"/></button> : <button onClick={() => this.startEdit(index)} className='plan_btn hide_print' ><img src={pencil} alt="redaguj"/></button> }
                        <button onClick={() => this.deleteMessageFromServer(this.state.data[index]._id)} className='plan_btn hide_print' ><img src={recycle} alt="redaguj"/></button> 
                    </td>
                </tr>
            );
        });
        
const btn = () => {
        return (
          <div>
            <button className='table__btn'onClick={this.addRow}><BiSolidClinic /> </button>
          </div>

        )
    }
    return (
        <div className="plan">
            <div className='plan_date'> Plan dnia: <textarea className="plan_date-input" defaultValue={`${formatDay}.${formatMouth}.${year}`}></textarea></div>
            <Table striped >
              <thead className="plan_table table-dark"> 
                <tr>
                  <th>NAZWA TOWARU</th>
                  <th>ILOŚCI/WERSIJE</th>
                  <th>NUMER ZLECENIA</th>
                  <th>UWAGI</th>
                  <th>TRANSPORT</th>
                  <th>#</th>
                </tr>
              </thead>
              <tbody className="plan_table ">
                {rows}
              </tbody>
            </Table>
            <div className="hide-on-print" >{btn()}</div>
        </div>
        );
    }
}

export default Plan;