import React, { Component } from 'react';

import './wz.scss';

class PrintGeranova extends Component {
    constructor(props){
        super(props);
        this.state = {

        }
    }



    render() {


        return (
            <div className="wyrob">
                <h2>FINAL PRODUCT</h2>
               <div className="wyrob-body">
                   <div className="wyrob-body_title">
                       <h4>ORDER NUMBER:</h4>
                       <textarea className="input-title" defaultValue={this.props.data[0].name}></textarea>
                   </div>
                    <div className="wyrob-body_title">
                       <h4>CONTRACT PARTNER:</h4>
                       <textarea className="input-title name" defaultValue="GERANOVA BRUCKMANN VERLAGSHAUS GMBH" ></textarea>
                   </div>
                    <div className="wyrob-body_title">
                       <h4>ORDER TITLE:</h4>
                       <textarea className="input-title name" ></textarea>
                   </div>
                   <div className="wyrob-body_item wyrob-body_item-isbn">
                        <div className="item">
                           <h4>QUANTITY ON THE PALETTE:</h4>
                           <textarea className="input-item" 
                                     defaultValue={this.props.data[0].count!=='' && this.props.data.length > 0 ? this.props.data[0].count.match(/^\d+/)[0] : ''} ></textarea>
                        </div>
                        <div className="item">
                           <h4>NEXT PALLET NUMBER:</h4>
                           <textarea className="input-item" defaultValue="1" ></textarea>
                        </div>
                        <div className="item">
                           <h4>TOTAL PALLETS NUMBER:</h4>
                           <textarea className="input-item" defaultValue={this.props.data[0].subcount }  ></textarea>
                        </div>

                   </div>
                    <div className="wyrob-body_title ">
                       <h4>ISBN NUMBER:</h4>
                       <textarea className="input-title input-title_isbn" defaultValue={this.props.data[0].title}  ></textarea>
                   </div>
               </div>
            </div>
        );
    }
}

export default PrintGeranova;